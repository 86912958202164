function FooterViewModel() {
    var self = this;

    var TROVACIGUSTO_SHARE_TEXT = 'I invite you to discover FindGusto, the platform that makes it easy to enjoy every day, and aims to "uplift" and improve the world using food as a lever!';
    var TROVACIGUSTO_SHARE_URL = window.location.origin;

    self.socialShareTrovacigusto = new SocialShareModule(TROVACIGUSTO_SHARE_TEXT, TROVACIGUSTO_SHARE_URL);


    return self;
}

if (document.getElementById("footer-bind") && !footer) {
    var footer = new FooterViewModel();
    ko.applyBindings(footer, document.getElementById("footer-bind"));
}