var foodGuruLimit = 100
var PROMO_FRIENDS_END = '01.03.2020'

!function (window) {
  'use strict'

  // Update this function, so it returns the height of your fixed headers
  function fixedHeaderOffset() {
    var width = window.innerWidth

    if (width < 992) {
      return 96
    } else {
      return 60
    }
  }

  // Run on first scroll (in case the user loaded a page with a hash in the url)
  window.addEventListener('scroll', onScroll)

  function onScroll () {
    window.removeEventListener('scroll', onScroll)
    scrollUpToCompensateForFixedHeader()
  }

  // Run on hash change (user clicked on anchor link)
  if ('onhashchange' in window) {
    window.addEventListener('hashchange', scrollUpToCompensateForFixedHeader)
  }

  function scrollUpToCompensateForFixedHeader () {
    var hash,
      target,
      offset

    // Get hash, e.g. #mathematics
    hash = window.location.hash
    if (hash.length < 2) {
      return
    }

    // Get :target, e.g. <h2 id="mathematics">...</h2>
    target = document.getElementById(hash.slice(1))
    if (target === null) {
      return
    }

    // Get distance of :target from top of viewport. If it's near zero, we assume
    // that the user was just scrolled to the :target.
    if (target.getBoundingClientRect().top < 2) {
      window.scrollBy(0, -fixedHeaderOffset())
    }
  }

}(window)

function isAppDevice () {
  return navigator.userAgent.toLowerCase().indexOf('apicodeversion') > -1
}

function isMobile () {
  return /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent.toLowerCase())
}

$(document).ready(function () {

  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

  //@TRELLO [10369]
  /* //Hide menu scroll on mobile
   if ($(window).width() < 991 && !iOS) {
     var $window = $(window)
     var prev = 0
     var nav = $('header')
     $window.on('scroll', function () {
       var scrollTop = $window.scrollTop()

       if (scrollTop > prev) {
         nav.removeClass('nav-down').addClass('nav-up')
       } else {
         nav.removeClass('nav-up').addClass('nav-down')
       }
       prev = scrollTop
     })
   }*/

  document.body.addEventListener('focus', function (event) {
    const target = event.target
    switch (target.tagName) {
      case 'INPUT':
      case 'TEXTAREA':
      case 'SELECT':
        document.body.classList.add('keyboard')
    }
  }, true)

  document.body.addEventListener('blur', function () {
    document.body.classList.remove('keyboard')
  }, true)

  $('.share').click(function (e) {
    if (!isAppDevice() && !isMobile()) {
      e.preventDefault()
      alert('This feature is only available via smartphone and tablet.')
    }

  })

  $('.tg-collapse').click(function () {
    $('.menu-sm').toggle()
  })

  var imageSvg = $('img.svg')
  if (Modernizr.svg) {
    imageSvg.each(function () {
      $(this).attr('width', $(this).attr('data-width'))
    })
  } else {
    var srcSvg = imageSvg.attr('src')
    if (srcSvg) {
      srcSvg = srcSvg.replace(/\.svg/, '.png')
      imageSvg.attr('src', srcSvg)
    }
  }

  $('.submit').click(function (e) {
    e.preventDefault()
    $(this).closest('form').submit()
  })

  if (location.hostname.includes('trovacigusto.studio') && window.TROVACIGUSTO) {
    console.log(window.TROVACIGUSTO)
  }

  $('body').on('click', function (e) {
    if (!$('li.dropdown.menu-notify').is(e.target)
      && $('li.dropdown.menu-notify').has(e.target).length === 0
      && $('.open').has(e.target).length === 0
    ) {
      $('li.dropdown.menu-notify').removeClass('open')
    }
  }).on('click', '.dropdown-menu.menu-notify-dropdown', function (e) {
    $(this).parent().is('.open') && e.stopPropagation()
  })

})

$(window).on('load', function () {
  if (document.getElementById('collapseBeta'))
    getCookie('isBeta') ? document.getElementById('collapseBeta').style.display = 'none' : document.getElementById('collapseBeta').style.display = null

  if (!getCookie('alertProfileImage') && $('#imageProfile').length) {
    document.getElementById('imageProfile').style.display = null
  }

  // if (isMobile() && !isAppDevice()) {
  //   console.log('detecting gonative '+isAppDevice())
  //   console.log('detecting ismobile '+isMobile())
  //   if (!getCookie('suggestDownloadApp') && !document.getElementById('home-page')) {
  //     Swal.fire({
  //       title: '<img src="/assets/trovacigusto/logo/svg/logo-app.svg" height="32" class="img-rounded-half mr-2" alt="Logo App Trovacigusto"> <span>Carry the Taste with you: download the App!</span>',
  //       html: '<p>Download the Lite App, lightweight and fast, for the best user experience!</p>' +
  //         '<div class="tg-flexContainer justify-center">\n' +
  //         '    <div class="mg-b-sm mg-r-sm mb-2 mr-1">\n' +
  //         '        <a class="link-app-android"\n' +
  //         '           href="https://play.google.com/store/apps/details?id=com.trovacigusto.webview">\n' +
  //         '            <img style="height:35px" src="/assets/trovacigusto/badge_google_play.png"/>\n' +
  //         '        </a>\n' +
  //         '    </div>\n' +
  //         '    <div class="mg-b-sm mg-r-sm mb-2">\n' +
  //         '        <a class="link-app-itunes"\n' +
  //         '           href="https://itunes.apple.com/it/app/trovacigusto/id1078565029?mt=8">\n' +
  //         '            <img style="height:35px" src="/assets/trovacigusto/badge_apple.png"/>\n' +
  //         '        </a>\n' +
  //         '    </div>\n' +
  //         '</div>',
  //       showCancelButton: false,
  //       showConfirmButton: true,
  //       confirmButtonText: 'I\'ll download it later',
  //       customClass: {
  //         confirmButton: 'btn btn-xxs mg-r-xs',
  //         cancelButton: 'btn btn-success',
  //       },
  //       buttonsStyling: false
  //     }).then(function (result) {
  //         if (result.value || result.dismiss) {
  //           setCookie('suggestDownloadApp', true, 7)
  //         }
  //       }
  //     )
  //   }
  // }

  loadLightBox()

})

function copyToClipboard (element) {
  var $temp = $('<input>')
  $('body').append($temp)
  $temp.val($(element).text()).select()
  document.execCommand('copy')
  $temp.remove()
  alert($(element).text() + '\n It has been copied')
}

/**
 * KO util, toggles a boolean value in an observable.
 * @param data is observable object
 */
function toggleObservable (data) {
  data(!data())
}

function cleanShareMessage (name, url, description) {
  var text = encodeURIComponent(name)
  if (description && description !== '')
    text += ': ' + encodeURIComponent((description.substr(0, 100)).replace(/<(?:.|\s)*?>/gm, '') + '...')
  return text + ' ' + encodeURIComponent(url)
}

function restoreLang () {
  var iframe = document.getElementsByClassName('goog-te-banner-frame')[0]
  if (!iframe) return

  var innerDoc = iframe.contentDocument || iframe.contentWindow.document
  var restore_el = innerDoc.getElementsByTagName('button')

  for (var i = 0; i < restore_el.length; i++) {
    if (restore_el[i].id.indexOf('restore') >= 0) {
      restore_el[i].click()
      var close_el = innerDoc.getElementsByClassName('goog-close-link')
      close_el[0].click()
      return
    }
  }
}

function toggle (item) {
  $(item).toggleClass('hidden')
  scrollToAnchor(item)
}

function scrollToAnchor (anchor, offsetTop, velocity) {

  var mq = window.matchMedia('(min-width: 767px)')

  var x = $(anchor).offset()
  if (x === undefined) {
    anchor = '#wrapper'
  }

  if (!offsetTop) {
    if (mq.matches) {
      offsetTop = 70
    } else {
      offsetTop = 100
    }
  }
  if (!velocity)
    velocity = 500
  if (isAppDevice() || isMobile()) {
    window.scrollTo(0, $(anchor).offset().top - offsetTop - 36)

  } else {
    var x = $(anchor).offset()
    if (x !== undefined) {
      $('html, body').animate({
        'scrollTop': $(anchor).offset().top - offsetTop
      }, velocity)
    }

  }
}

/**
 *  Cateringa remote KO validator setting.
 */
if (ko && ko.validation) {
  ko.validation.locale('it-IT')
  var uri = getCookie('auth_token') ? '/api/v2/validate' : '/api/v2/guests/validate'
  ko.validation.rules['remote'] = {
    async: true,
    validator: function (value, data, callback) {
      if (value !== null) {
        rest('POST', uri, { value: value, data: data }).then(function (response) {
          if (response.message) {
            callback({ isValid: response.success, message: response.message })
          } else {
            callback(response.success)
          }
        })
      }
    }
  }
  ko.validation.registerExtenders()

  ko.validation.init({
    decorateInputElement: true,
    errorElementClass: 'text-danger input-error',
    errorMessageClass: 'help-block text-danger text-xs'
  }, true)
}


// Fix Date.toJSON timezone offset
// @credits http://stackoverflow.com/a/11383043/1641777
Date.prototype.toJSONLocal = (function () {
  function addZ (n) {
    return (n < 10 ? '0' : '') + n
  }

  return function () {
    return this.getFullYear() + '-' +
      addZ(this.getMonth() + 1) + '-' +
      addZ(this.getDate())
  }
}())

var i18n = {
  'it': {
    'offerType': {
      'menu': 'Menù',
      'special': 'Menù in promozione',
      'promotion-dish': 'Voce di menù in promozione',
      'promotion-discount': 'Sconto',
      'promotion-gift': 'Promozione',
      'event': 'Evento'
    },
    'offerConditions': {
      'fan': 'Solo per fan',
      'bookingNo': 'Non prenotabile',
      'bookingOptional': 'Prenotazione consigliata',
      'bookingMandatory': 'Prenotazione obbligatoria',
      'limited': 'Disponibilità limitata'
    },
    'offerDeliveryConditions': {
      'time': 'Consegna media in %d minuti'
    }
  }
}

function setCookie (name, value, days, path) {
  if (!value)
    value = true
  if (days)
    Cookies.set(name, value, { expires: days })
  else
    Cookies.set(name, value)
}

function getCookie (name) {
  return !!Cookies.get(name)

}

window.downloadFile = function (sUrl) {

  if (/(iP)/g.test(navigator.userAgent)) {
    window.open(sUrl, '_blank')
    return false
  }

  if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1 || navigator.userAgent.toLowerCase().indexOf('safari') > -1) {
    var link = document.createElement('a')
    link.href = sUrl
    link.setAttribute('target', '_blank')

    if (link.download !== undefined) {
      link.download = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length)
    }

    if (document.createEvent) {
      var e = document.createEvent('MouseEvents')
      e.initEvent('click', true, true)
      link.dispatchEvent(e)
      return true
    }
  }

  if (sUrl.indexOf('?') === -1) {
    sUrl += '?download'
  }

  window.open(sUrl, '_blank')
  return true
}

/**
 *
 * @param diffTime unix timestamps
 * @param element css selector
 * @param format display format
 */
function dateCountdown (diffTime, element, format) {

  var duration = moment.duration(diffTime * 1000, 'milliseconds')
  var interval = 1000
  if (diffTime > 0) {
    var intervalFunction = setInterval(function () {
      duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds')
      if (duration <= 0) {
        $(element).text('00:00:00')
        clearInterval(intervalFunction)
      }
      var day = moment.duration(duration.asMilliseconds() - interval, 'milliseconds').asDays()
      if (day >= 1) {
        $(element).text(duration.format(format ? format : 'd [Giorni e] hh[h] mm[m] ss[s]'))
        //$(element).text(duration.days()+' Giorni e '+duration.hours() + ":" + duration.minutes() + ":" + duration.seconds())

      } else {
        $(element).text(duration.format(format ? format : 'hh[h] mm[m] ss[s]'))
      }
    }, interval)
  } else {
    $(element).text('00:00:00')
  }

}

function setPromoFriendsEndDate (date) {
  var endDate = moment(date, ['DD.MM.YYYY', 'DD-MM-YYYY'])
  var eventTime = endDate.unix()
  var currentTime = moment().unix()
  var diffTime = eventTime - currentTime

  dateCountdown(diffTime, '.countdownPromoFriends')
}

setPromoFriendsEndDate(PROMO_FRIENDS_END)

function t (context, key) {
  var locale = 'it'
  return i18n[locale][context][key]
}

function loadLightBox (element, selector) {

  var options = {
    speed: 500,
    download: false,
    licenseKey: 'DBE8C333-32C240D8-987F665E-AE04D329',
    mobileSettings: {showCloseIcon: true},
  }

  if (!element || element === '.tg-image-full') {
    element = '.tg-image-full'
    options.selector = 'this'
  }
  if (selector)
    options.selector = selector

  if (typeof lightGallery === 'function') {
    var imagesFull = $(element)
    if (imagesFull && imagesFull.length) {
      for (var item of imagesFull) {
        lightGallery(item, options)
      }
    }
  }
}

/**
 *
 * @param sParam
 */
function getUrlParameterValue (sParam) {
  if (!sParam)
    return null
  var sPageURL = window.location.search.substring(1)
  var sURLVariables = sPageURL.split('&')
  for (const queryParam of sURLVariables) {
    const item = queryParam.split('=')
    if (item[0] === sParam) {
      return decodeURIComponent(item[1])
    }
  }

  return null
}

function debugLog (data) {
  if (typeof Bugsnag !== 'undefined')
    Bugsnag.notify(data)
  console.log(data)

}

function sendFacebookEvent (event, name, paramenters) {
  if (isAppDevice()) {
    var data = {
      event: getFacebookEventNameMobile(name),
      parameters: paramenters
    }
    if (typeof gonative !== 'undefined' && typeof gonative.facebook !== 'undefined')
      gonative.facebook.events.send(data)
  } else {
    if (typeof fbq !== 'undefined')
      fbq(event, name, paramenters)
  }
}

function getFacebookEventNameMobile (name) {
  switch (name) {
    case 'AddToCart':
      return 'fb_mobile_add_to_cart'
    case 'Purchase':
      return 'fb_mobile_purchase'
    case 'InitiateCheckout':
      return 'fb_mobile_initiated_checkout'
    default:
      return 'fb_mobile_content_view'
  }

}

if (document.getElementById('mobile-menu-extra')) {
  ko.applyBindings(new menuExtra(), document.getElementById('mobile-menu-extra'))
}